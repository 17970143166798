.login_page_block .form-control {
  color: #48464f;
  opacity: 1;
}
.form-control:focus {
  color: #48464f;
  background-color: white !important;
  border-color: #614199 !important;
  box-shadow: none !important;
  outline: 0;
}
input.form-control::placeholder {
  color: #b3afb8 !important;
  opacity: 1 !important;
}

.has-float-label label {
  color: #7f7d89;
  font-family: "BeamNewHMK-114";
  width: max-content;
  text-transform: none;
  top: -10px;
  background-color: white;
  padding: 0 5px;
  font-size: 15px;
  border-radius: 4px;
}

/* .head-title h4 {
  margin-bottom: 2rem !important;
} */
.login_page_block .form-group {
  margin-top: 2rem;
  margin-bottom: 0rem !important;
}

.form-group {
  /* Items should be 38px from border to border, not sure how this gets us there, but I think the label adds space */
  padding-top: 18px;
  margin-bottom: 18px;
}

.login_page_block .btn-secondary {
  background-color: #73717d !important;
  border-color: #73717d !important;
}
.greeting-content {
  text-align: center;
  vertical-align: middle;
  margin: 20px auto;
  color: #6a57a5;
  width: 400px;

  /* padding: 220px 0px; */
  /* background-image: url(../images/account-side.jpg); */

}

.form-check-label {
  margin-bottom: 0;
  font-size: 14px;
}

.create-link {
  text-decoration: 1px solid underline #0056b3;
  color: #0056b3;
}

/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #6a57a5;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #6a57a5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #6a57a5;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #74717e !important;
  border-color: #74717e !important;
  opacity: 1;
}

.form-group.invalid input{
  border:1px solid #dc3545 ;
  background-color: white;
}

.form-group.invalid label{
  color: #dc3545;
}

@-moz-document url-prefix() {
  .has-float-label label { 
    top: 5px !important;
  }
}