/*.nav-top-section{
  z-index: 200;  
}
.navbar-collapse {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 71px;
  z-index: 100;
  background: white;
}
*/



.screen-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}




.bg-dark {
  background-color: white !important;
  padding: 0;
}



 
.nav-shadow-alltime, .nav-mobile-shadow {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}
.nav-top-section {
  flex-grow: 1;
  display: flex;
}
.navbar-brand { 
  /* the logo - centered on mobile */
  margin: 0px auto;
  padding-right: 50px;
}
.navbar-nav {
  margin-left: auto;
}


@media (min-width: 992px) {
  /* LG,XL  aka Desktop */
  .navbar {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  }
  .nav-mobile-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .navbar-brand {
    margin: 0; /* Left on desktop */
    padding-right: 0;
  }
  .nav-top-section {
    flex-grow: 0
  }
  .navbar-nav {
    justify-content: space-between;
    margin-left: 20px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .navbar-nav {
    margin-left: 80px;
  }
}




.navbar-dark .navbar-nav .nav-link.black-link {
    color: #48464f;
    font-family: "BeamNewHMK-114";
    font-size: 15px;
    line-height: 33px;
    display: block;
}



.navbar-dark .navbar-nav .nav-link {
  font-family: 'IBM Plex Serif', sans-serif;
  color: #614095;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: normal;
  background: none;
  border: none;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #614095;
}
.nav-link img {
  width: 18px;
}


@media (max-width: 991px) {
  /* mobile only */
  .nav-item {
    border-bottom: 1px solid #b3b0b9;
  }
}


.navbar-text {
  font-size: 14px;
  font-weight: 500;
}
.navbar-text button {
  font-size: 14px;
  font-weight: 500;
}

.navbar-collapse.collapse footer {
  display: none;
}

@media (max-width: 991px) {
  /* mobile only */
  .navbar-collapse.collapse.show {
      min-height: 77vh;
  }
}

.navbar-collapse.collapse.show footer {
  display: block;
  position: fixed;
  bottom: 0;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url(../images/HMK-Icons/Icons_Menu_Regular.svg);
}
.navbar-dark .navbar-text a, .navbar-dark .navbar-text .link {
  color: #48464f;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text .link:hover {
  color: #000000;
  text-decoration: underline;
}

/* hide close when burger shown */
.navbar-toggler.collapsed .close-icon {
  display: none;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}


.nav-link img.signout-icon{
  width: 24px;
  height: 23px;
  display: inline-block;
  margin-right: 15px; /* this seems like a lot, but it is right -EG */
}
.nav-link img.signin-icon {
  width: 21px;
  height: 24px;
  display: inline-block;
  margin-right: 15px; /* this seems like a lot, but it is right -EG */
}


/* footer design start */

footer {
  padding: 10px;
  padding-bottom: 80px;
  background-color: #efeae5;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 150;
}
/*@media (min-width: 992px) { */
  /* lg ~ Desktop */
  footer {
    position: relative;
    margin-top: auto;
  }
/*}*/

footer a, footer a:link, footer a:active, footer a:visited, footer a:hover, footer a:focus, footer .link {
  color: #295d97;
  padding-right: 10px;
  border-right: 1px solid grey;
  text-decoration: 1px solid underline;
  font-weight: 500;
}
.footer_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}
.footer_inner li {
  list-style: none;
  padding: 10px 5px;
  color: #295d97;
}
.copy_right {
  font-size: 14px;
  font-weight: 500;
  color: #4a4850;
  margin-bottom: 10px;
}

.simple-footer {
  margin-top: auto;
}
nav .hide-in-nav, .simple-footer .hide-in-nav {
  display: none;
}
nav .hide-in-nav.d-lg-block, .simple-footer .hide-in-nav {
    display: none !important;
}

.sticky {
  position: fixed;
}

/* footer design end */