body {
  background-color: #e0e0e0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a {
  color: black;
  cursor: pointer;
}

.actions {
  text-align: center;
}

.vflogo {
  width: auto;
}

.vflogo img {
  width: auto;
  height: auto;
}

.fa-power-off {
  font-size: 20px;
  margin: 0px 10px;
}

/* .card {
  background-color: #ffffff !important;
} */
.notSelectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.nonDraggableImage {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  -moz-user-focus: ignore;
}

.plyr__video-wrapper {
  padding-bottom: 0% !important;
}

.plyr__control--overlaid {
  background:#ffa06a !important;
  color: white;
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
.plyr--video .plyr__controls .plyr__control:hover,
.plyr--video .plyr__controls .plyr__control[aria-expanded="true"] {
  background: #603695 !important;
  color: #fff;
}

.plyr--full-ui input[type="range"] {
  color: #51347c !important;
}

.upgradedCard {
  border: 4px solid #604199;
}

.video-thumb {
  display: inline-block;
  position: relative;
}
.video-thumb::before {
content:"";
width: 15%;
height: 50%;
bottom: 55%;
left: 89%;
margin-left:-9%;
position: absolute;
background: url(../images/Icons_Play.svg);
background-position: center center;
background-size: contain;
background-repeat: no-repeat;
opacity: 1;
}

.video-thumb:hover::before {
  width: 19%;
  margin-left: -11%;
}
.video-thumb:press::before {
  width: 12%;
  margin-left: -6%;
}
.fizzStatusBadge {
}

.fizzStatusBadge::before {
}

.jsShareDownloadLink {
  color: #604199;
}

.videocontainer {
  position: relative;
  text-align: center;
  color: black;
}

.captionOutput {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #ffffff73;
  width: 100%;
  height: auto;
}

.upgrade-text {
  text-align: center;
  font-weight: 700;
}


.customButtonOutline, .btn-outline-primary,
.customButton, .btn-primary {
  font-family: "BeamNewHMK-114", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.7px;
}

.customButton, .btn-primary {
  background-color: #604199;
  border: 1px solid #604199;
  border: 0;
  color: white;
}

.customButtonOutline, .btn-outline-primary {
  border: 1px solid #604199;
  color: #604199;
  background-color: white;
  text-transform: uppercase;
}

.btn.btn-img {
  padding: 0;
  background: transparent;
}
button.btn.btn-img:focus {
  box-shadow: none !important;
  background: transparent;
}
.btn.btn-img img {
  border-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


.customBadge {
  border-radius: 0px;
  background-color: #202020;
  border: 0;
  color: white !important;
}


.shareModal {
  display: contents;
}

.shareDialog {
  padding: 15px;
  background: white;
  z-index: 10;
  box-shadow: none;
}
p.tight {
  margin-bottom: 0;
}
p.indent {
  margin-left: 15px;
}
.shareDialog input,
.shareDialog textarea {
  width: 100%;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
}
.shareDialog textarea {
  height: 100px;
}
a.shareBtn {
  background: #eee;
  color: #495057;
  border: 2px solid #eee;

  height: 60px;
  width: 60px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  margin: 0 3px;
}
q.shareBtn:link,
a.shareBtn:visited {
  color: #495057;
}
a.shareBtn:hover {
  border: 2px solid #555;
  text-transform: none;
}

.shareBtn i {
  display: inline-block;
  width: 100%;
  font-size: 23px;
  margin-top: 11px;
  margin-bottom: 6px;
}
.shareBtn img {
  height: 32px;
  margin-top: 8px;
}
.shareBtn .fa-facebook {
  color: #4757b7;
}

.shareView {
  display: none;
  padding: 15px 0;
}
.shareView.open {
  display: block;
}

/* Unbranded video */

.checkcontainer {
  position: relative;
  float: left;
}

.checkboximage {
  position: absolute;
  top: 10px;
  left: 10px;
  zoom: 3.2;
}

/* Login page css */

.header-back {
  font-size: 22px;
  line-height: 50px;
  color: #55d209;
}

.header-back:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 11px 9px 0;
  border-color: transparent #202020 transparent transparent;
  margin-bottom: -1px;
  display: inline-block;
  margin-right: 10px;
}

.pull-right {
  float: right !important;
}

header {
  background-color: #604199;
  padding: 5px 0;
  text-align: center;
}

.width-wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 0 0px;
}
.sub-width-wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 25px;
}

.login-page {
  padding: 20px 0;
}

.login-col {
  padding-top: 45px;
  padding-bottom: 45px;
}

.login-info {
  z-index: 1;
}

.login-page input.form-control {
  font-size: 18px;
  height: 44px;
}

.form-control {
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #cbcaca;
  color: #202020;
  border-radius: 10px;
  height: auto;
  padding: 11px;
}
.modal-header {
  text-align: center;
  background: white;
  color: #524f4f;
}
.modal-header h1,
.modal-header h2,
.modal-header h3 {
  text-align: left;
  width: 100%;
}

#page {
  background-color: #fefefe;
}

.login-info h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: bold;
  color: #54cd0a;
  padding-left: 40px;
}

.login-info ul li {
  font-size: 19px;
  color: #202020;
  margin: 0;
  padding: 0 0 0 36px;
  list-style: none;
  background-image: url(../img/list-bullet.png);
  background-repeat: no-repeat;
  background-position: left 0.6em;
  background-size: 16px;
}

.login-info::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  /* background: url(../img/login-bg-girl.jpg); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.signInButton {
  background-color: #603695 !important;
}

.footer-callout h3 {
  font-size: 34px;
}

.footer-callout p {
  font-size: 21px;
  color: #54cd0a;
}

.footer-support-left {
  display: block;
  float: left;
  margin-right: 10px;
}

.footer-support-gap {
  height: 25px;
}

.footer-support-right {
  display: block;
  float: left;
  line-height: 1.2;
}

footer {
  background: #202020;
  padding: 39px 0;
  height: -webkit-fill-available;
}

footer,
footer p,
footer a,
footer a:link,
footer a:active,
footer a:visited,
footer a:hover,
footer a:focus {
  color: #f6f6f6;
}

footer .footer-bottom {
  color: #a39f9c;
  text-align: right;
  margin-top: 10px;
  margin-left: 1px;
}

.list-group-item {
  background-color: #e0e0e0;
  margin-top: 5px;
  padding-left: 40px;
}
.list-group-item i.fa {
    position: absolute;
    left: 18px;
    top: 14px;
}

/* Tutorials */

.captionText {
  font-size: 0.9rem;
  float: left;
}

.playIcon {
  font-size: 0.9rem;
  text-align: right;
  padding-right: 15px;
}

/* Edit Fizz */

.musicOptions {
  padding: 1rem;
  background-color: #202020;
  color: white;
}
.music-picker-list .icon-checkmark {
  position: absolute;
  left: 20px;
}
.music-picker-list .icon-checkmark, .sound-picker-list .icon-checkmark {
  position: absolute;
  left: 20px;
}

.musicOptions > input[type="radio"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  -ms-appearance: checkbox; /* not currently supported */
  -o-appearance: checkbox; /* not currently supported */
}

.plyr audio,
.plyr video {
  border-radius: inherit;
  /*max-height: 500px !important;   Some are larger. If needed add a new class to a wrapper. The preview page doesn't wokr with this*/
  vertical-align: middle;
  width: 100%;
}
.plyr:-webkit-full-screen video {
  max-height: none !important;
}

.fizzStatusBadge {
  float: right;
  background-color: #604199;
  margin: 0 -18px 0 0;
  color: white;
  top: 0;
  right: 0;
  position: absolute;
  padding: 5px 27px;
}

.uploading-image {
  background-size: contain;
  padding-top: 57%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;
}


.card-body-loading {
  padding: 0;
}

.loading-bar {
  height: 10px;
  background-color: #614095;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}
.loading-bar-wrap {
  display: inline-block;
  background: white;
  border: 1px solid #614095;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: 1px 0px;
}

.uploading-video {
  width: 100%;
  background-color: black;
  line-height: 0;
  vertical-align: middle;
  position: relative;
}
img.uploading-video {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.uploading-video video {
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  position: absolute;
}
.uploading-video-gap {
  width: 0;
  height: 1px;
  padding-top: 56.25%;
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
}

label.btn {
  margin-bottom: 0;
}
.btn-upload > input {
  display: none;
}

.box-480 {
  position: relative;
  width: 100%;
}
.box-480:before {
  width: 100%;
  height: 0;
  padding-top: 56%;
  content: " ";
  display: block;
}
.box-480-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}



.navbar {
  z-index: 200;
}

.btn-back {
  font-size: 20px;
}
@media (min-width: 1444px) {
  .btn-back {
    font-size: 16px;
    position: absolute;
    left: -50%;
    text-align: right;
    width: 50%;
  }
}

/* Create Fizz page */

.iconBox {
  border-radius: 25px;
  /* width: 200px;
  height: 200px; */
  width: 150px;
  height: 150px;
  background-color: #5647acac;
}

.iconBoxBack {
  content: "";
  border-radius: 25px;
  /* width: 200px;
  height: 200px; */
  width: 150px;
  height: 150px;
  /* background-color: #000000; */
  margin: 8% auto;
}
.iconBoxBack h6 {
  color: black !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #604199;
}

input:focus + .slider {
  box-shadow: 0 0 1px #604199;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.description {
  text-align: center;
  font-size: 20px;
  background: #604199;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 0;
  color: white;
  font-weight: bold;
}
.description-gap {
  height: 10px;
  width: 100%;
}

.listing-img {
  width: 100%;
  height: 0;
  padding: 60% 0 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.listing {
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.listing.selected {
  border: 2px solid #604199;
}

.listing-address {
  font-weight: bold;
}

.listing-address,
.listing-title {
  margin-bottom: 0;
}

.listing-photos {
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.clip-img {
  width: 100%;
  height: 0;
  padding: 62% 0 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 4px solid transparent;
  margin-bottom: 10px;
}
.clip-img.selected {
  border: 4px dashed #604199;
}

.buttonIcon {
  padding: 6px 6px 6px 0 !important;
  margin: 10px 10px 10px 0;
}

.navbar-dark .navbar-text .link {
  color: #fff;
}

button.link {
  background: none;
  border: none;
  padding: 0;
}
button.link:hover {
  text-decoration: underline;
}

.btn {
  font-family: "BeamNewHMK-114", sans-serif;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  font-weight: 700;
  padding: 18px 24px;
  line-height: 1;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7752be;
  border-color: #7752be;
}
.btn-primary, .btn-primary:active {
  color: #fff;
  background-color: #604099;
  border-color: #604099;
  font-size: 14px;
}
.btn-primary:hover,
.btn-primary:focus {
  color: #fff !important;
  background-color: #753bbd;
  border-color: #753bbd;
  text-decoration: none !important;
}
.btn-outline-primary {
  color: #604099;
  background-color: white;
  border-color: #604099;
  font-size: 14px;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #604099;
  border-color: #604099;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}
.btn-outline-primary:hover {
  background-color: white !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  color:#753bbd !important;
  border-color: #753bbd;
  text-decoration: none !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #fff;
  background-color: #74717e !important;
  border-color: #74717e !important;
  opacity: 1;
}

/* .show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  color: #fff;
  background-color: #604199;
  border-color: #604199;
} */

/* .show > .btn-primary.dropdown-toggle,
.btn-primary:active {
  color: #fff;
  background-color: #604199;
  border-color: #604199;
} */

@media (min-width: 992px) {
  .btn-lg-inline-block {
    display: inline-block;
    width: auto;
  }
}



.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #604099;
}

.btn-link {
  text-decoration: underline;
  color: black;
}
.btn-link:hover {
  color: #2679ba;
  text-decoration: underline;
}

.alert-dot {
  position: relative;
  width: 12px;
  display: inline-block;
}
.alert-dot::after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #604199;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 0;
  box-sizing: content-box;
}
.alert-dot::before {
  content: "";
  border: 3px solid #604199;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -8px;
  top: -33px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
  box-sizing: content-box;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* CUSTOM NAV BAR */

.banner-image {
  position: absolute;
  left: 500px;
  right: 200px;
  background-size: cover;
  background-position: center center;
  height: 100%;
  z-index: -1;
}

.img-thumb {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  background-color: #000000;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.tutorial-link {
  display: inherit;
}



.step_snap::before {
  content: "1";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #604199;
  z-index: 100;
  left: 14px;
  top: 14px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
}
.react-multi-carousel-item:nth-child(2) .step_snap::before {
    content: "2";
}
.react-multi-carousel-item:nth-child(3) .step_snap::before {
    content: "3";
}
.react-multi-carousel-item:nth-child(4) .step_snap::before {
    content: "4";
}

/* Register/Invite Trial Register styling */
.sign-form h1.headline {
  font-size: 2rem;
}

.sign-form ul.cta-pointers {
  font-weight: normal;
}

.sign-form ul.cta-pointers li {
  font-weight: normal;
}

.sign-form ul.cta-pointers li::marker {
  color: #61429A;
  font-size: 1.8rem;
}

.marquee {
  position: relative;
  overflow: hidden;
  --offset: 0rem;
  --move-initial: calc(0% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 40s linear infinite;
  animation-play-state: running;
}
.marquee img {
  height: 3.5rem;
}

@keyframes marquee {
  0% {
      transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
      transform: translate3d(var(--move-final), 0, 0);
  }
}

.marquee span span.occasion-label {
  font-size: 1rem;
}

.card-orange {
  background-color: #f79f6b;
  border-radius: 10px;
}

.mask-gray {
  background-color: #48464f!important;
}

/* MultiVideoPlayer screen styling */
.queue-item-wrapper video {
  max-width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .queue-item-wrapper:nth-child(2) {
    padding: 0 0.5rem;
  }
}

/* SharedFizz Redeem Offer styling */
.cta-description p {
  font-size: 1rem;
}

.cta-description .cta-caption {
  font-size: 0.8rem;
}

/* SharedFizz Comments Styling */
.comment-author {
  font-size: 1rem !important;
  margin: 0 0.5rem !important;
  font-weight: 600 !important;
}

.form-control.comment-input, .form-control.comment-input:focus {
  background-color: #f4f4f4;
  border: none;
}

.reply-button {
  color: #61429A;
}

.remove-button {
  color: #7D7D7D;
}

.comment-top-level button, .comment-reply button {
  text-decoration: none !important;
  font-size: 0.7rem;
  margin-top: 0.2rem;
}

.comment-top-level p, .comment-reply p {
  font-size: 1rem;
}

.reply-container {
  margin-left: 4rem;
}

button.disable-comments {
  color: #2D5881;
  font-size: 0.7rem;
}

.cancel-link i {
  color: #7D7D7D;
}

.remove-comment-modal,
.remove-comment-modal .modal-content,
.remove-comment-modal .modal-header {
  background-color: #EFEFEF;
  border: none;
}

.remove-comment-modal,
.remove-comment-modal .modal-header,
.remove-comment-modal > .modal-content {
  border-radius: 0.6rem;
}

.remove-comment-modal .modal-header h3 {
  color: #4C4A53;
}

.remove-comment-modal p {
  font-size: 1rem;
  color: #000;
}

@media (min-width: 768px) {
  .remove-comment-modal button {
    width: 40%;
  }
}
/* QRBox Screens Styling */
.QRBox-thumbnail-wrapper {
  width: 30%;
}

@media (max-width: 767px) {
  .QRBox-thumbnail-wrapper {
    width: 75%;
  }
}

@media (max-width: 1023px) {
  .QRBox-thumbnail-wrapper {
    width: 50%;
  }
}

.qr-box-cta-wrapper, .qr-box-preview-wrapper {
  width: 50%
}

@media (max-width: 767px) {
  .qr-box-cta-wrapper, .qr-box-preview-wrapper {
    width: 100%;
  }
}

.switch-profile-link {
  color: #1611A0;

}

/*** QuickSignup Flow Styling ***/
/* EditFizz */
.quick-signup-cta-btn, .quick-signup-cta-btn:active {
  background-color: #FFA06A;
  border: 1px solid #FFA06A;
}

/*** Donate Banner Component Styling ***/
.donate-banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #F238A6;
}

.donate-logo-wrapper {
  background-color: white;
  flex-shrink: 1;
  padding: 3% 10% 3% 3%;
  margin: -1px;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
}

.donate-caption {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  line-height: 1.5rem;
  padding-left: 48px;
  flex-grow: 1;
}

.btn-donate {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2rem;
  color: #F238A6;
  background-color: white;
  border: 2px solid #F238A6;
  text-transform: uppercase;
  padding: 24px 96px;
  margin: 0;
}

.btn-donate:hover, .btn-donate:hover {
  background-color: #F238A6;
  color: white;
  border: 2px solid white;
}

.donate-button-wrapper {
  margin: 0 24px;
}

@media (max-width: 991px) {
  .donate-button-wrapper {
    flex-basis: calc(100% + 1px);
    padding: 24px;
    margin: -1px;
    background-color: white;
  }

  .btn-donate {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .donate-logo-wrapper {
    flex-basis: 40%;
    transform: scaleY(1.2);
  }

  .donate-button-wrapper {
    padding: 16px;
  }

  .donate-logo-wrapper img {
    width: 80%;
  }

  .btn-donate {
    padding: 16px 20%;
  }

  .donate-caption {
    font-size: 1rem;
    line-height: 1rem;
  }
}

/* QRBoxThankYou screen styling */
@media (min-width: 768px) {
  .thank-you-desktop-grid {
    margin-bottom: 32px;
  }
}




.ugly_markup a {
  color: #295d97;
  text-decoration: underline;
  font-weight: 500;
}
.ugly_markup h2.page-title {
  font-family: "IBM Plex Serif", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #48464f;
  margin: 14px 0 0 0;
}












